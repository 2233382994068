<template>
  <div class="test-area">
    {{data}}
    <base-slider
      v-model="data"
      label="0 = não gostei, 10 = gostei"
    />
  </div>
</template>

<script>
export default {
  name: 'TestArea',
  data(){
    return {
      data: 1
    }
  }
}
</script>

<style>
.test-area {
  display: flex;
  flex-direction: column;
  padding:1rem;
  background: white;
}
</style>